import { Suspense, lazy, useEffect, useState } from 'react'
import { useLocation, Route, Routes } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Spinner from '../components/Spinner'
import { darkTheme, lightTheme } from '../global/theme'
import FullHeight from '../components/FullHeight'

const Home = lazy(() => import('../pages/Home'))
const About = lazy(() => import('../pages/About'))
const Work = lazy(() => import('../pages/Work'))
const SingleWork = lazy(() => import('../pages/SingleWork'))
const Contact = lazy(() => import('../pages/Contact'))
const Information = lazy(() => import('../pages/Information'))
const NotFound = lazy(() => import('../pages/NotFound'))

const Router = () => {
  const [theme, setTheme] = useState(lightTheme)
  const location = useLocation()

  useEffect(() => {
    localStorage.getItem('theme') === darkTheme.theme
      ? setTheme(darkTheme)
      : setTheme(lightTheme)
  }, [theme])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])

  return (
    <Suspense
      fallback={
        <FullHeight>
          <Spinner theme={theme} />
        </FullHeight>
      }
    >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/work' element={<Work />} />
        <Route path='/work/:id' element={<SingleWork />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/information' element={<Information />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default Router
