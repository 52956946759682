export const lightTheme = {
  theme: 'light',
  palette: {
    background: 'rgba(250, 250, 250, 1)',
    headerBg: '#1F81B0',
    sidemenu: '#5F5F5F',
    hover: '#F6F6F6',
    title: '#4A4E56',
    service: '#4a4e56',
    still: '#1F81B0',
    line: '#A9ADB6',
    divider: '#b5b9c2',
  },
}

export const darkTheme = {
  theme: 'dark',
  palette: {
    background: 'rgba(0, 0, 0, 1)',
    headerBg: '#001924',
    sidemenu: '#AEAEAE',
    hover: '#232323',
    title: '#e5e5e5',
    service: '#4a4e56',
    still: '#1F81B0',
    line: '#A9ADB6',
    divider: '#b5b9c2',
  },
}
