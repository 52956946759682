import styled from "styled-components";

export const FooterStyle = styled.div`
  text-align: center;
  background: ${(props) => props.theme.palette.headerBg};
  color: #fff;
  padding: 30px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
  .top_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
      font-size: 14px;
    }
  }
  .logo {
    width: 150px;
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
    @media (max-width: 768px) {
      align-items: center;

      padding: 0;
    }
    ul {
      display: flex;
      list-style: none;
      gap: 50px;
      font-size: 20px;
      padding: 40px 0;
      @media (max-width: 768px) {
        padding: 20px 0;
        justify-content: center;
        gap: 15px;
      }
      li {
        cursor: pointer;
      }
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .list {
      a {
        color: #fff;
      }
    }
  }
  .socials {
    padding: 30px 0;
    @media (max-width: 768px) {
      padding: 10 0;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 60px;
      justify-content: center;
      align-items: center;
      a {
        font-size: 25px;
        color: #fff;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .copyright {
    position: relative;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    &:after {
      position: relative;
      z-index: -1;
      content: "";
      display: block;
      width: 400px;
      height: 10px;
      margin-top: -10px;
      background-color: ${(props) => props.theme.palette.divider};
      animation: fadeInDown 1.5s ease-in-out;
      @media (max-width: 768px) {
        width: 250px;
      }
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 768px) {
      justify-content: center;
    }
    .divider {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
