import React, { useState } from 'react';


import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineMoon } from "react-icons/hi";
import { BsLightbulb } from "react-icons/bs";
import { IoClose, IoDiamondOutline } from "react-icons/io5";
import { LiaLanguageSolid } from "react-icons/lia";
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { MdWorkOutline } from 'react-icons/md';
import { RiContactsBookLine, RiServiceLine } from 'react-icons/ri';


import BtnTheme from '../BtnTheme';
import { useTranslation } from 'react-i18next';


import logo from '../../assets/menuLogo.png';
import darkLogo from '../../assets/menuDarkLogo.png'
import { SideStyle } from './style';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/directionContext';


const SideMenu = ({ close, closeClick, theme }) => {
  const { i18n, t } = useTranslation();
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const navigate = useNavigate();
  const { direction } = useLanguage();

  const ChangeLang = (lang) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    setShowLanguageMenu(false);

    closeClick(); // This function should change the state to close the side menu
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeClick(); // This function should change the state to close the side menu
  };

  return (
    <>
      <SideStyle className={close ? "show" : ""} dir={direction}>
        <aside>
          <ul>
            <li className='head'>
              <div className='logo'>
                {theme === "dark" ?
                  <img src={darkLogo} alt="logo" />
                  :
                  <img src={logo} alt="logo" />
                }
              </div>
              <div>
                <IoClose className='closeIcon' onClick={closeClick} />
              </div>
            </li>
            <hr />
            <div className='secondSection'>
              <li onClick={() => handleNavigation('/')}><AiOutlineHome /><div>{t('home')}</div></li>
              <li onClick={() => handleNavigation('/about')}><BsLightbulb /><div>{t('who')}</div></li>
              <li onClick={() => handleNavigation('/#service')}><RiServiceLine /><a href="/#service">{t('services')}</a></li>
              <li onClick={() => handleNavigation('/work')}><MdWorkOutline />{t('work')}</li>
              <li onClick={() => handleNavigation('/information')}><IoDiamondOutline />{t('info')}</li>
              <li onClick={() => handleNavigation('/contact')}><RiContactsBookLine />{t('contact')}</li>
            </div>
            <hr />
            <div className="thirdSection">
              <li className='head-languages' onClick={() => setShowLanguageMenu(!showLanguageMenu)}>
                <div className='languages-toggle'>
                  <LiaLanguageSolid /> {t('lang')}
                  {showLanguageMenu ? <BiSolidUpArrow style={{ fontSize: "10px" }} /> : <BiSolidDownArrow style={{ fontSize: "10px" }} />}

                </div>
                {showLanguageMenu && (
                  <ul className='arabic-english-menu'>
                    <li onClick={() => ChangeLang("ar")}>{t("arabic")}</li>
                    <li onClick={() => ChangeLang("en")}>English</li>
                  </ul>
                )}
              </li>
              <li className='theme-btns'>
                <div>
                  <HiOutlineMoon /> {t("mode")}
                </div>
                <div className='btn-theme' >
                  <BtnTheme />
                </div>
              </li>
            </div>
          </ul>
        </aside>
      </SideStyle>
    </>
  )
}

export default SideMenu;
