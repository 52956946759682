import React from 'react'
import { Container } from '../../../global/component'

const Error = () => {
  return (
    <>
      <Container>
        <p
          style={{
            color: '#000',
            marginTop: '150px',
            textAlign: 'center',
          }}
        >
          Sth went wrong ,refresh page
        </p>
      </Container>
    </>
  )
}

export default Error
