import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
    margin : 0;
    padding : 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
a{
    text-decoration:none;
}
.root{
    overflow-x: hidden;
}
body{
    background:${(props) => props.theme.palette.background};
    direction:${(props) => props.dir};
    font-family: bold;
    transition:.8s;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes bounceInDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    70% {
        transform: translateY(30px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}

.hero-order {
    text-align: center;
}

.hero-order button{
    background: #1e81b0;
    cursor: pointer;
    color: #fff;
}

.whatsapp-float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    left: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-size: 27px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  
  .whatsapp-float:hover {
    text-decoration: none;
    color: white;
    background-color: #128c7e;
  }
  
  .whatsapp-float a {
    color: white;
  }
  

.works-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.works-flex span{
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
    text-decoration: underline;
}

@keyframes fadeInDown {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

`
