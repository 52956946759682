import { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useTranslation } from 'react-i18next'

// google analytics
import ReactGA from 'react-ga4'

import { lightTheme, darkTheme } from './global/theme'
import { GlobalStyle } from './global/style'

import { themeContext } from './context/themeContext'
import { LanguageProvider } from './context/directionContext'

import Header from './components/Header'
import ArrowToTop from './components/ArrowButton'
import Router from './Router'
import Footer from './components/Footer'
import WhatsAppButton from './components/WhatsAppButton'

const TRACKING_ID = 'G-QBY6MEV1ET'
ReactGA.initialize(TRACKING_ID)

export default function App() {
  const { i18n } = useTranslation()
  const [theme, setTheme] = useState(lightTheme)

  useEffect(() => {
    localStorage.getItem('theme') === darkTheme.theme
      ? setTheme(darkTheme)
      : setTheme(lightTheme)
  }, [theme])

  const direction = i18n.language === 'en' ? 'ltr' : 'rtl'

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <themeContext.Provider value={[theme, setTheme]}>
          <GlobalStyle dir={direction} />
          <Header theme={theme} />
          <Router />
          <WhatsAppButton />
          <Footer />
          <ArrowToTop />
        </themeContext.Provider>
      </LanguageProvider>
    </ThemeProvider>
  )
}
