import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // 

const WhatsAppButton = () => {
  const phoneNumber = '0568117840'; // Replace with your phone number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-float"
      target="_blank"
      rel="noopener noreferrer"
      title="Chat with us on WhatsApp"
    >
      <FaWhatsapp size={30} /> {/* WhatsApp Icon */}
    </a>
  );
};

export default WhatsAppButton;
