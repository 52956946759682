import React, { useState } from 'react'

import darkLogo from '../../assets/darklogo.png';

import { RiMenu3Fill, RiMenu2Line } from "react-icons/ri";

import { Container } from '../../global/component';
import { headerVariants } from "../../utils/motion";
import { motion } from 'framer-motion';
import { HeaderStyle } from './styled';
import { useLanguage } from '../../context/directionContext';
import { useNavigate } from 'react-router-dom';


import NotMobile from './../NotMobile/index';
import Mobile from '../Mobile';
import SideMenu from '../SideMenu';



const Header = ({ theme }) => {
    const { direction } = useLanguage();
    const navigate = useNavigate();

    const [close, setClose] = useState(false);

    const closeClick = () => {
        setClose(false);
    };
    const openClick = () => {
        setClose(true);
    };
    return (
        <>
            <HeaderStyle>
                <Container>
                    <NotMobile>
                        <motion.div
                            variants={headerVariants}
                            initial="hidden"
                            whileInView="show"
                            className={`bg-primary paddings headers-own-style`}
                            viewport={{ once: true, amount: 0.25 }}
                        >
                            <div className="sidebar">
                                {direction === "rtl" ?
                                    <RiMenu3Fill className='icons' onClick={openClick} /> :
                                    <RiMenu2Line className='icons' onClick={openClick} />
                                }
                            </div>
                            <div className="logo" onClick={() => navigate('/')}>
                                <img src={darkLogo} alt="logo" />
                            </div>
                        </motion.div>
                    </NotMobile>
                    <Mobile>
                        <div className='headers-own-style'>
                            <div className="sidebar">
                                {direction === "rtl" ?
                                    <RiMenu3Fill className='icons' onClick={openClick} /> :
                                    <RiMenu2Line className='icons' onClick={openClick} />
                                }
                            </div>
                            <div className="logo" onClick={() => navigate('/')}>
                                <img src={darkLogo} alt="logo" />
                            </div>
                        </div>
                    </Mobile>
                </Container>
            </HeaderStyle>
            <SideMenu
                close={close}
                closeClick={closeClick}
                theme={theme.theme}
            />
        </>
    )
}

export default Header