import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const OrderButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const OrderStyle = styled.button`
    background: #fff;
    padding: 15px 60px;
    cursor: pointer;
    color: #000;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: bold;
  `
  return (
    <OrderStyle onClick={() => navigate('/contact')}>{t('order')}</OrderStyle>
  )
}

export default OrderButton
