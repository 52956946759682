import React from 'react'

import img from '../../assets/darklogo.png'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BsInstagram } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import { Container } from '../../global/component'
import { FooterStyle } from './style'
import OrderButton from '../OrderButton'

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <FooterStyle>
      <Container>
        <div className='top_footer'>
          <div className='menu'>
            <div className='btn'>
              <span className='divider'></span>
              <OrderButton />
            </div>
            <div className='list'>
              <ul>
                <li onClick={() => navigate('/')}>
                  <div>{t('home')}</div>
                </li>
                <li onClick={() => navigate('/about')}>
                  <div>{t('who')}</div>
                </li>
                <li onClick={() => navigate('/#service')}>
                  <a href='/#service'>{t('services')}</a>
                </li>
                <li onClick={() => navigate('/work')}>{t('work')}</li>
                <li onClick={() => navigate('/information')}>{t('info')}</li>
                <li onClick={() => navigate('/contact')}>{t('contact')}</li>
              </ul>
            </div>
          </div>
          <div className='logo'>
            <img src={img} alt='logo' />
          </div>
        </div>
        <div className='socials'>
          <ul>
            <li>
              <a href='https://www.instagram.com/_ziadstudio/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'>
                <BsInstagram />
              </a>
            </li>
            <li>
              <a href='mailto:mail@ziadstudio.co'>
                <FiMail />
              </a>
            </li>
          </ul>
        </div>
        <div className='copyright'>{t('copyRight')}</div>
      </Container>
    </FooterStyle>
  )
}

export default Footer
